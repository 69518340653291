:root {
  --primary: #eb5e28;
  --primary-dark: #cd4813;
  --secondary: #45b5c4;
  --accent: #f9cb40;
  --dark: #121212;
  --gray-dark: #252422;
  --gray-medium: #403d39;
  --gray-light: #ccc5b9;
  --off-white: #fffcf2;
  --white: #fff;
  --shadow-sm: 0 1px 2px 0 #0000000d;
  --shadow-md: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
  --shadow-lg: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
  --shadow-xl: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
  --border-radius: 8px;
  --transition: all .3s ease;
  --jonquil: #f9c80e;
  --giants-orange: #f86624;
  --red-pantone: #ea3546;
  --rebecca-purple: #662e9b;
  --moonstone: #43bccd;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 80px;
}

body {
  color: var(--gray-dark);
  background-color: var(--white);
  font-family: Inter, Arial, sans-serif;
  font-size: clamp(16px, 1vw, 18px);
  line-height: 1.6;
  overflow-x: hidden;
}

h1, h2, h3, h4, h5 {
  color: var(--dark);
  font-family: Inter, sans-serif;
  font-weight: 700;
  line-height: 1.2;
}

h1 {
  font-size: clamp(2.5rem, 5vw, 3.5rem);
}

h2 {
  margin-bottom: 1.5rem;
  font-size: clamp(2rem, 4vw, 2.5rem);
}

h3 {
  margin-bottom: 1rem;
  font-size: clamp(1.5rem, 3vw, 1.8rem);
}

p {
  margin-bottom: 1rem;
}

a {
  color: var(--primary);
  transition: var(--transition);
  text-decoration: none;
}

a:hover {
  color: var(--primary-dark);
}

img {
  max-width: 100%;
  height: auto;
}

h2#logo {
  font-optical-sizing: auto;
  letter-spacing: 3px;
  align-items: center;
  margin: 0;
  font-family: Inter, sans-serif;
  font-size: 3rem;
  font-weight: 700;
  display: flex;
}

h2#logo svg {
  flex-shrink: 0;
  width: 4rem;
  height: 4rem;
}

.container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.jonquil {
  background-color: var(--jonquil) !important;
}

.giants-orange {
  background-color: var(--giants-orange) !important;
}

.red-pantone {
  background-color: var(--red-pantone) !important;
}

.rebecca-purple {
  background-color: var(--rebecca-purple) !important;
}

.moonstone {
  background-color: var(--moonstone) !important;
}

.btn {
  border-radius: var(--border-radius);
  text-align: center;
  cursor: pointer;
  transition: var(--transition);
  white-space: nowrap;
  padding: .75rem 1.5rem;
  font-weight: 500;
  display: inline-block;
}

.btn-primary {
  background: var(--primary);
  color: var(--white);
  box-shadow: var(--shadow-md);
}

.btn-primary:hover {
  background: var(--primary-dark);
  color: var(--white);
  box-shadow: var(--shadow-lg);
  transform: translateY(-3px);
}

.btn-secondary {
  background: var(--secondary);
  color: var(--white);
}

.btn-secondary:hover {
  color: var(--white);
  background: #3ca3b1;
}

.btn-outline {
  color: var(--primary);
  border: 1px solid var(--primary);
  background: none;
}

.btn-outline:hover {
  background: var(--primary);
  color: var(--white);
}

.header {
  z-index: 1000;
  -webkit-backdrop-filter: blur(10px);
  width: 100%;
  box-shadow: var(--shadow-sm);
  transition: var(--transition);
  background-color: #fffffff2;
  position: fixed;
  top: 0;
  left: 0;
}

.header.scrolled {
  box-shadow: var(--shadow-md);
}

.header-container {
  justify-content: space-between;
  align-items: center;
  padding: 1.25rem 0;
  display: flex;
  position: relative;
}

.logo {
  color: var(--dark);
  align-items: center;
  gap: .5rem;
  font-family: Inter, sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  display: flex;
}

.logo-icon {
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  display: flex;
}

.nav {
  align-items: center;
  display: flex;
}

.nav-list {
  gap: 2rem;
  list-style: none;
  display: flex;
}

.nav-link {
  color: var(--gray-dark);
  font-weight: 500;
  position: relative;
}

.nav-link:hover, .nav-link.active {
  color: var(--primary);
}

.nav-link.active:after {
  content: "";
  background-color: var(--primary);
  width: 100%;
  height: 2px;
  transition: width .3s;
  position: absolute;
  bottom: -5px;
  left: 0;
}

.nav-link:after {
  content: "";
  background-color: var(--primary);
  width: 0;
  height: 2px;
  transition: width .3s;
  position: absolute;
  bottom: -5px;
  left: 0;
}

.nav-link:hover:after {
  width: 100%;
}

.header-actions {
  align-items: center;
  gap: 1rem;
  display: flex;
}

.mobile-menu-btn {
  cursor: pointer;
  color: var(--gray-dark);
  z-index: 1001;
  background: none;
  border: none;
  padding: .5rem;
  font-size: 1.5rem;
  display: none;
}

.hero {
  background-color: #fffcf2;
  background-image: radial-gradient(#f39e7c .5px, #fffcf2 .5px);
  background-size: 10px 10px;
  padding: 10rem 0 6rem;
  position: relative;
  overflow: hidden;
}

.highlight {
  color: var(--primary);
}

.hero-container {
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 4rem;
  display: grid;
}

.hero-content {
  max-width: 100%;
}

.hero-subtitle {
  color: var(--primary);
  background-color: #e04e391a;
  border-radius: 50px;
  margin-bottom: 1.5rem;
  padding: .5rem 1rem;
  font-size: .875rem;
  font-weight: 500;
  display: inline-block;
}

.hero-title {
  margin-bottom: 1.5rem;
}

.hero-description {
  color: var(--gray-medium);
  margin-bottom: 2rem;
  font-size: 1.125rem;
}

.hero-actions {
  gap: 1rem;
  margin-bottom: 2rem;
  display: flex;
}

.hero-image {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  display: flex;
  position: relative;
}

.floating-card {
  background: var(--white);
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-lg);
  width: 100%;
  max-width: 400px;
  margin-bottom: 1.5rem;
  padding: 1.5rem;
  transition: transform .3s, box-shadow .3s;
}

.floating-card:hover {
  box-shadow: var(--shadow-xl);
  transform: translateY(-5px);
}

.card-icon {
  background: var(--off-white);
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  display: flex;
}

.card-title {
  color: var(--dark);
  margin-bottom: .25rem;
  font-size: .875rem;
  font-weight: 600;
}

.card-subtitle {
  color: var(--gray-medium);
  font-size: .75rem;
}

.services {
  padding: 6rem 0;
  position: relative;
}

.section-header {
  text-align: center;
  max-width: 700px;
  margin: 0 auto 4rem;
}

.section-subtitle {
  color: var(--primary);
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 1rem;
  font-weight: 500;
}

.section-description {
  color: var(--gray-medium);
}

.services-grid {
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  display: grid;
}

.service-card {
  border-radius: var(--border-radius);
  transition: var(--transition);
  height: 100%;
}

.service-card-inner {
  background-color: var(--white);
  border-radius: 0 0 var(--border-radius) var(--border-radius);
  box-shadow: var(--shadow-md);
  height: 100%;
  margin-top: 1.5rem;
  padding: 1rem 2rem 2rem;
}

.service-card:hover {
  box-shadow: var(--shadow-lg);
  transform: translateY(-5px);
}

.service-icon {
  width: 60px;
  height: 60px;
  color: var(--primary);
  background-color: #e04e391a;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  display: inline-flex;
}

.service-title {
  margin-bottom: 1rem;
}

.service-description {
  color: var(--gray-medium);
}

.case-studies {
  background-image: radial-gradient(#eb5e28 .5px, #fffcf2 .5px);
  background-size: 10px 10px;
  padding: 6rem 0;
}

.cases-grid {
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  display: grid;
}

.case-card {
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-md);
  background: var(--white);
  transition: var(--transition);
  overflow: hidden;
}

.case-card:hover {
  box-shadow: var(--shadow-lg);
  transform: translateY(-5px);
}

.case-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: var(--transition);
}

.case-card:hover .case-image img {
  transform: scale(1.05);
}

.case-tag {
  background-color: var(--primary);
  width: max-content;
  color: var(--white);
  border-radius: 50px;
  margin: 1.5rem 0 0 1.5rem;
  padding: .25rem .75rem;
  font-size: .75rem;
  font-weight: 500;
}

.case-content {
  padding: 1.5rem;
}

.case-title {
  margin-bottom: .75rem;
}

.case-description {
  color: var(--gray-medium);
  margin-bottom: 1.5rem;
}

.cta {
  text-align: center;
  padding: 6rem 0;
  position: relative;
  overflow: hidden;
}

.cta-card {
  background: var(--white);
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-xl);
  z-index: 1;
  max-width: 800px;
  margin: 0 auto;
  padding: 3rem;
  position: relative;
}

.cta-title {
  margin-bottom: 1.5rem;
}

.cta-description {
  color: var(--gray-medium);
  max-width: 600px;
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
}

.footer {
  background-color: var(--dark);
  color: var(--gray-light);
  padding: 4rem 0 1rem;
}

.footer-container {
  grid-template-columns: 2fr repeat(3, 1fr);
  gap: 4rem;
  display: grid;
}

.footer-description {
  color: var(--gray-light);
  gap: 1rem;
  margin-bottom: 1.5rem;
  display: flex;
}

.footer-socials {
  gap: 1rem;
  display: flex;
}

.social-link {
  width: 40px;
  height: 40px;
  color: var(--white);
  transition: var(--transition);
  background-color: #ffffff1a;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  display: flex;
}

.social-link:hover {
  background-color: var(--primary);
  color: var(--white);
}

.footer-heading {
  color: var(--off-white);
  margin-bottom: 1.5rem;
  font-size: 1.125rem;
}

.footer-links {
  list-style: none;
}

.footer-link {
  margin-bottom: .75rem;
}

.footer-link a {
  color: var(--gray-light);
  transition: var(--transition);
}

.footer-link a:hover {
  color: var(--white);
}

.footer-contact-item {
  gap: 1rem;
  margin-bottom: 1rem;
  display: flex;
}

.contact-icon {
  color: var(--primary);
}

.contact-text {
  color: var(--gray-medium);
}

.footer-bottom {
  text-align: center;
  color: var(--gray-medium);
  border-top: 1px solid #ffffff1a;
  margin-top: 3rem;
  padding-top: 3rem;
  font-size: .875rem;
}

.mobile-menu-overlay {
  z-index: 900;
  background-color: #00000080;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

@media screen and (width <= 768px) {
  .mobile-menu-overlay.active {
    display: block;
  }
}

@media screen and (width <= 1200px) {
  .container {
    width: 95%;
  }
}

@media screen and (width <= 992px) {
  h1 {
    font-size: clamp(2rem, 4vw, 3rem);
  }

  h2 {
    font-size: clamp(1.8rem, 3.5vw, 2.3rem);
  }

  h3 {
    font-size: clamp(1.3rem, 2.5vw, 1.6rem);
  }

  .hero {
    padding: 8rem 0 4rem;
  }

  .hero-container {
    grid-template-columns: 1fr;
    gap: 3rem;
  }

  .hero-content {
    text-align: center;
    margin: 0 auto;
  }

  .hero-actions {
    justify-content: center;
  }

  .hero-image {
    flex-flow: wrap;
    justify-content: center;
    gap: 2rem;
    display: flex;
  }

  .floating-card {
    width: calc(50% - 2rem);
    min-width: 220px;
    margin: .5rem;
  }

  .services-grid, .cases-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .footer-container {
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;
  }
}

@media screen and (width <= 768px) {
  .mobile-menu-btn {
    display: block;
  }

  .nav {
    background-color: var(--white);
    width: 100%;
    box-shadow: var(--shadow-md);
    z-index: 1000;
    padding: 1rem 0;
    display: none;
    position: fixed;
    top: 80px;
    left: 0;
  }

  .nav.active {
    display: block;
  }

  .nav-list {
    flex-direction: column;
    gap: 0;
  }

  .nav-list li {
    text-align: center;
    width: 100%;
  }

  .nav-link {
    padding: 1rem;
    display: block;
  }

  .nav-link:after, .nav-link.active:after {
    display: none;
  }

  .nav-link.active {
    background-color: #e04e391a;
    font-weight: 600;
  }

  .header:has(.nav.active) {
    background-color: var(--white);
  }

  .header-actions {
    display: none;
  }
}

@media screen and (width <= 576px) {
  .services, .case-studies {
    padding: 4rem 0;
  }

  .services-grid, .cases-grid {
    grid-template-columns: 1fr;
  }

  .floating-card {
    width: 100%;
    margin: .5rem 0;
  }

  .hero-actions {
    flex-direction: column;
    gap: 1rem;
  }

  .hero-actions .btn {
    width: 100%;
  }

  .section-header {
    margin-bottom: 2.5rem;
  }

  .cta-card {
    padding: 2rem 1.5rem;
  }

  .footer-container {
    grid-template-columns: 1fr;
    gap: 2.5rem;
  }

  .footer-links-column {
    text-align: center;
  }

  .footer-contact-item, .footer-socials {
    justify-content: center;
  }
}
/*# sourceMappingURL=index.5da0f759.css.map */
