:root {
  --primary: #eb5e28;
  --primary-dark: #cd4813;
  --secondary: #45b5c4;
  --accent: #f9cb40;
  --dark: #121212;
  --gray-dark: #252422;
  --gray-medium: #403d39;
  --gray-light: #ccc5b9;
  --off-white: #fffcf2;
  --white: #ffffff;
  --shadow-sm: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  --shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --shadow-xl: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  --border-radius: 8px;
  --transition: all 0.3s ease;
  --jonquil: #f9c80eff;
  --giants-orange: #f86624ff;
  --red-pantone: #ea3546ff;
  --rebecca-purple: #662e9bff;
  --moonstone: #43bccdff;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 80px; /* Ensures content isn't hidden behind fixed header */
}

/* Base styles with responsive typography */
body {
  font-family: "Inter", Arial, sans-serif;
  color: var(--gray-dark);
  line-height: 1.6;
  background-color: var(--white);
  overflow-x: hidden;
  font-size: clamp(16px, 1vw, 18px);
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Inter", sans-serif;
  color: var(--dark);
  font-weight: 700;
  line-height: 1.2;
}

h1 {
  font-size: clamp(2.5rem, 5vw, 3.5rem);
}

h2 {
  font-size: clamp(2rem, 4vw, 2.5rem);
  margin-bottom: 1.5rem;
}

h3 {
  font-size: clamp(1.5rem, 3vw, 1.8rem);
  margin-bottom: 1rem;
}

p {
  margin-bottom: 1rem;
}

a {
  text-decoration: none;
  color: var(--primary);
  transition: var(--transition);
}

a:hover {
  color: var(--primary-dark);
}

img {
  max-width: 100%;
  height: auto;
}

h2#logo {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  letter-spacing: 3px;
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 3rem;
}

h2#logo svg {
  height: 4rem;
  width: 4rem;
  flex-shrink: 0;
}

.container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.jonquil {
  background-color: var(--jonquil) !important;
}

.giants-orange {
  background-color: var(--giants-orange) !important;
}

.red-pantone {
  background-color: var(--red-pantone) !important;
}

.rebecca-purple {
  background-color: var(--rebecca-purple) !important;
}

.moonstone {
  background-color: var(--moonstone) !important;
}

.btn {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  border-radius: var(--border-radius);
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  transition: var(--transition);
  white-space: nowrap;
}

.btn-primary {
  background: var(--primary);
  color: var(--white);
  box-shadow: var(--shadow-md);
}

.btn-primary:hover {
  background: var(--primary-dark);
  color: var(--white);
  transform: translateY(-3px);
  box-shadow: var(--shadow-lg);
}

.btn-secondary {
  background: var(--secondary);
  color: var(--white);
}

.btn-secondary:hover {
  background: #3ca3b1;
  color: var(--white);
}

.btn-outline {
  background: transparent;
  color: var(--primary);
  border: 1px solid var(--primary);
}

.btn-outline:hover {
  background: var(--primary);
  color: var(--white);
}

/* Header */
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: var(--shadow-sm);
  transition: var(--transition);
}

.header.scrolled {
  box-shadow: var(--shadow-md);
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.25rem 0;
  position: relative;
}

.logo {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
  color: var(--dark);
}

.logo-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
}

.nav {
  display: flex;
  align-items: center;
}

.nav-list {
  display: flex;
  list-style: none;
  gap: 2rem;
}

.nav-link {
  color: var(--gray-dark);
  font-weight: 500;
  position: relative;
}

.nav-link:hover {
  color: var(--primary);
}

.nav-link.active {
  color: var(--primary);
}

.nav-link.active::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--primary);
  transition: width 0.3s ease;
}

.nav-link::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 0;
  height: 2px;
  background-color: var(--primary);
  transition: width 0.3s ease;
}

.nav-link:hover::after {
  width: 100%;
}

.header-actions {
  display: flex;
  gap: 1rem;
  align-items: center;
}

/* Mobile navigation */
.mobile-menu-btn {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: var(--gray-dark);
  padding: 0.5rem;
  z-index: 1001;
}

/* Hero Section */
.hero {
  position: relative;
  padding: 10rem 0 6rem;
  overflow: hidden;
  background-color: #fffcf2;
  background-image: radial-gradient(#f39e7c 0.5px, #fffcf2 0.5px);
  background-size: 10px 10px;
}

.highlight {
  color: var(--primary);
}

.hero-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  align-items: center;
}

.hero-content {
  max-width: 100%;
}

.hero-subtitle {
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: rgba(224, 78, 57, 0.1);
  color: var(--primary);
  border-radius: 50px;
  font-weight: 500;
  font-size: 0.875rem;
  margin-bottom: 1.5rem;
}

.hero-title {
  margin-bottom: 1.5rem;
}

.hero-description {
  font-size: 1.125rem;
  color: var(--gray-medium);
  margin-bottom: 2rem;
}

.hero-actions {
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
}

.hero-image {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
}

.floating-card {
  background: var(--white);
  border-radius: var(--border-radius);
  padding: 1.5rem;
  box-shadow: var(--shadow-lg);
  width: 100%;
  max-width: 400px;
  margin-bottom: 1.5rem;
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
}

.floating-card:hover {
  transform: translateY(-5px);
  box-shadow: var(--shadow-xl);
}

.card-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background: var(--off-white);
}

.card-title {
  font-weight: 600;
  font-size: 0.875rem;
  color: var(--dark);
  margin-bottom: 0.25rem;
}

.card-subtitle {
  font-size: 0.75rem;
  color: var(--gray-medium);
}

/* Services Section */
.services {
  padding: 6rem 0;
  position: relative;
}

.section-header {
  text-align: center;
  max-width: 700px;
  margin: 0 auto 4rem;
}

.section-subtitle {
  color: var(--primary);
  font-weight: 500;
  margin-bottom: 1rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.section-description {
  color: var(--gray-medium);
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.service-card {
  border-radius: var(--border-radius);
  transition: var(--transition);
  height: 100%;
}

.service-card-inner {
  margin-top: 1.5rem;
  padding: 1rem 2rem 2rem 2rem;
  background-color: var(--white);
  border-radius: 0 0 var(--border-radius) var(--border-radius);
  box-shadow: var(--shadow-md);
  height: 100%;
}

.service-card:hover {
  transform: translateY(-5px);
  box-shadow: var(--shadow-lg);
}

.service-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 12px;
  background-color: rgba(224, 78, 57, 0.1);
  color: var(--primary);
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
}

.service-title {
  margin-bottom: 1rem;
}

.service-description {
  color: var(--gray-medium);
}

/* Case Studies */
.case-studies {
  padding: 6rem 0;
  background-image: radial-gradient(#eb5e28 0.5px, #fffcf2 0.5px);
  background-size: 10px 10px;
}

.cases-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.case-card {
  border-radius: var(--border-radius);
  overflow: hidden;
  box-shadow: var(--shadow-md);
  background: var(--white);
  transition: var(--transition);
}

.case-card:hover {
  transform: translateY(-5px);
  box-shadow: var(--shadow-lg);
}

.case-image {
  /* position: relative;
  aspect-ratio: 16 / 9;
  overflow: hidden; */
}

.case-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: var(--transition);
}

.case-card:hover .case-image img {
  transform: scale(1.05);
}

.case-tag {
  width: max-content;
  margin: 1.5rem 0 0 1.5rem;
  /* position: absolute;
  top: 1rem;
  left: 1rem; */
  padding: 0.25rem 0.75rem;
  border-radius: 50px;
  background-color: var(--primary);
  color: var(--white);
  font-size: 0.75rem;
  font-weight: 500;
}

.case-content {
  padding: 1.5rem;
}

.case-title {
  margin-bottom: 0.75rem;
}

.case-description {
  color: var(--gray-medium);
  margin-bottom: 1.5rem;
}

/* CTA Section */
.cta {
  padding: 6rem 0;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.cta-card {
  background: var(--white);
  border-radius: var(--border-radius);
  padding: 3rem;
  box-shadow: var(--shadow-xl);
  max-width: 800px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.cta-title {
  margin-bottom: 1.5rem;
}

.cta-description {
  color: var(--gray-medium);
  margin-bottom: 2rem;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

/* Footer */
.footer {
  padding: 4rem 0 1rem;
  background-color: var(--dark);
  color: var(--gray-light);
}

.footer-container {
  display: grid;
  grid-template-columns: 2fr repeat(3, 1fr);
  gap: 4rem;
}

.footer-description {
  display: flex;
  color: var(--gray-light);
  margin-bottom: 1.5rem;
  gap: 1rem;
}

.footer-socials {
  display: flex;
  gap: 1rem;
}

.social-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--white);
  transition: var(--transition);
  font-size: 1.5rem;
}

.social-link:hover {
  background-color: var(--primary);
  color: var(--white);
}

.footer-heading {
  font-size: 1.125rem;
  color: var(--off-white);
  margin-bottom: 1.5rem;
}

.footer-links {
  list-style: none;
}

.footer-link {
  margin-bottom: 0.75rem;
}

.footer-link a {
  color: var(--gray-light);
  transition: var(--transition);
}

.footer-link a:hover {
  color: var(--white);
}

.footer-contact-item {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.contact-icon {
  color: var(--primary);
}

.contact-text {
  color: var(--gray-medium);
}

.footer-bottom {
  text-align: center;
  padding-top: 3rem;
  margin-top: 3rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  color: var(--gray-medium);
  font-size: 0.875rem;
}

.mobile-menu-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 900;
}

/* Header overlay for mobile */
@media screen and (max-width: 768px) {
  .mobile-menu-overlay.active {
    display: block;
  }
}

/* Responsive Grid Adjustments */
@media screen and (max-width: 1200px) {
  .container {
    width: 95%;
  }
}

/* Tablet View */
@media screen and (max-width: 992px) {
  h1 {
    font-size: clamp(2rem, 4vw, 3rem);
  }

  h2 {
    font-size: clamp(1.8rem, 3.5vw, 2.3rem);
  }

  h3 {
    font-size: clamp(1.3rem, 2.5vw, 1.6rem);
  }

  .hero {
    padding: 8rem 0 4rem;
  }

  .hero-container {
    grid-template-columns: 1fr;
    gap: 3rem;
  }

  .hero-content {
    text-align: center;
    margin: 0 auto;
  }

  .hero-actions {
    justify-content: center;
  }

  .hero-image {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 2rem;
    flex-wrap: wrap;
  }

  .floating-card {
    margin: 0.5rem;
    width: calc(50% - 2rem);
    min-width: 220px;
  }

  .services-grid,
  .cases-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .footer-container {
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;
  }
}

/* Mobile View */
@media screen and (max-width: 768px) {
  .mobile-menu-btn {
    display: block;
  }

  .nav {
    display: none;
    position: fixed;
    top: 80px;
    left: 0;
    width: 100%;
    background-color: var(--white);
    box-shadow: var(--shadow-md);
    padding: 1rem 0;
    z-index: 1000;
  }

  .nav.active {
    display: block;
  }

  .nav-list {
    flex-direction: column;
    gap: 0;
  }

  .nav-list li {
    width: 100%;
    text-align: center;
  }

  .nav-link {
    display: block;
    padding: 1rem;
  }

  .nav-link::after,
  .nav-link.active::after {
    display: none;
  }

  .nav-link.active {
    background-color: rgba(224, 78, 57, 0.1);
    font-weight: 600;
  }

  .header:has(.nav.active) {
    background-color: var(--white);
  }

  .header-actions {
    display: none;
  }
}

@media screen and (max-width: 576px) {
  .services {
    padding: 4rem 0;
  }

  .case-studies {
    padding: 4rem 0;
  }

  .services-grid,
  .cases-grid {
    grid-template-columns: 1fr;
  }

  .floating-card {
    width: 100%;
    margin: 0.5rem 0;
  }

  .hero-actions {
    flex-direction: column;
    gap: 1rem;
  }

  .hero-actions .btn {
    width: 100%;
  }

  .section-header {
    margin-bottom: 2.5rem;
  }

  .cta-card {
    padding: 2rem 1.5rem;
  }

  .footer-container {
    grid-template-columns: 1fr;
    gap: 2.5rem;
  }

  .footer-links-column {
    text-align: center;
  }

  .footer-contact-item {
    justify-content: center;
  }

  .footer-socials {
    justify-content: center;
  }
}
